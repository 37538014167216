<template>
  <div class="NotFound">
    <v-container>
          <v-card style="max-width: 400px" class="mx-auto pa-6 my-12 text-center">
            <div class="display-1 mt-5">PAGE NOT FOUND</div>
            <div class="paragraph-text mt-2">
              <br />
              <br />Try going back and reapeating your action. 
              <br />
              Or contact admin for support.
            </div>
          </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({})
};
</script>
